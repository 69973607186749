<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Forgot Password</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center" v-if="step == 1">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <Mobile
                :value="mobile"
                label="Mobile number"
                :rules="mobileRules"
                @input="mobile = $event"
                @getCode="code = $event"
              ></Mobile>
            </div>
            <div>
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                :loading="api.isLoading"
                @click="submit()"
              >
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="close()"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
        <div v-else-if="step == 2" class="form-content text-center">
          <div>
            <v-icon size="150" color="black"> mdi-email-fast </v-icon>
          </div>
          <div class="text-h5 pt-1 font-weight-black">Reset Link Sent</div>
          <div class="pt-2">It is sent to <b>{{ mobile }}</b>.</div>
          <div class="text-center pt-3 mb-3">
            <v-btn width="75%" class="mb-2" rounded color="primary" :to="{ name: 'PageMainHome' }" dark> Okay</v-btn>
            <v-btn width="75%" rounded color="primary" outlined @click="decrease()">Try Again</v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import Mobile from "@/components/Form/Mobile.vue";
export default {
  components: {
    Mobile
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    valid: true,
    mobile: null,
    mobileRules: [],
    step: 1,
    code: 'MYS',
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  watch: {
    mobile: function () {
      this.mobileRules = [];
    },
    resendTime: function () {
      if (this.resendTime < 1) {
        clearInterval(this.setInterval);
      }
    },
  },
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: "Home",
      to: { name: "PageMainHome" },
      exact: true,
    });

    this.breadcrumbs.push({
      text: "Forgot Password",
      to: { name: "PageAccountForgetPassword" },
      exact: true,
    });

    this.api.url =
      this.$api.servers.log + "/api/v1/" + this.$_getLocale() + "/token/send";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.step = 2;
    };
  },
  mounted() {
   // this.sendOtp();
  },

  methods: {
    close() {
      this.$router.push({ name: "PageMainLogin" });
    },
    increase() {
      this.step++;
    },
    decrease() {
      this.step--;
    },
    submit() {
      this.mobileRules = [
        () =>
          (!!this.mobile &&
            this.mobile.length >= 7 &&
            this.mobile.length <= 15) ||
          "Mobile number has to be between 7 and 15",
        () =>
          Number.isInteger(Number(this.mobile)) ||
          "The Mobile number must be an integer number",
      ];
    
      let self = this;
      setTimeout(function () {
        if (self.$refs.form.validate()) {
          self.api.params = self.$_.clone({
            mobile: self.mobile,
            countryCode: self.code,
          });
          self.$api.fetch(self.api);
        } else {
          self.api.callbackError("Please check the form.");
        }
      });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>